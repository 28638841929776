<template>
  <b-input-group>
    <b-form-input
      :value="value"
      :type="showPassword ? 'text' : 'password'"
      v-bind="$attrs"
      @input="$emit('input', $event)"
    />
    <b-input-group-append>
      <b-button size="sm" variant="outline-info" class="bg-white text-dark d-flex align-items-center justify-content-center" style="border-color: #dfdfdf;" @click="$emit('update:showPassword', !showPassword)">
        <fa :icon="showPassword ? 'eye-slash' : 'eye'" fixed-width />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>

export default {
  name: 'JbPasswordInput',
  inheritAttrs: false,
  props: {
    value: {
      type: undefined,
      required: true
    },
    showPassword: {
      type: Boolean,
      required: true
    }
  }
}
</script>
